const CommonRouter = {
    charts_of_account: '/charts-of-account',
    chat: '/chat',
    company_create: '/company/create',
    company_edit: '/company/edit',
    company_view: '/company/view',
    company_statutory_create: '/company/statutory-info/create',
    company: '/company',
    dashboard: '/dashboard',
    balance_transfer: '/balance-transfer',
    expense_create: '/expense/create',
    expense_edit: '/expense/edit',
    expense_view: '/expense/view',
    expense: '/expense',
    faq: '/faq',
    gst_sales_hsn_report: '/gst-compliance/gst-sales-hsn',
    gst_file_report: '/gst-compliance/gst-file',
    gst_purchase_hsn_report: '/gst-compliance/gst-purchase-hsn',
    hsn_sales_report: '/gst-compliance/hsn-sales',
    gstr2a_report: '/gst-compliance/gstr-2a',
    gstr3b_report: '/gst-compliance/gstr-3b',
    cancel_eway_report: '/gst-compliance/cancel-eway-bill-report',
    generate_eway_report: '/gst-compliance/generate-eway-bill-report',
    cancel_einvoice_report: '/gst-compliance/cancel-einvoice-report',
    generate_einvoice_report: '/gst-compliance/generate-einvoice-report',
    gstr1_amendment_report: '/gst-compliance/gstr1-amendment',
    gst_compliance: '/gst-compliance',
    item: '/item',
    jv_create: '/journal-voucher/create',
    jv_edit: '/journal-voucher/edit',
    jv_view: '/journal-voucher/view',
    journal_voucher: '/journal-voucher',
    sj_create: '/stock-journal/create',
    sj_edit: '/stock-journal/edit',
    sj_view: '/stock-journal/view',
    stock_journal: '/stock-journal',
    gjv_create: '/gst-journal-voucher/create',
    gjv_edit: '/gst-journal-voucher/edit',
    gjv_view: '/gst-journal-voucher/view',
    gst_journal_voucher: '/gst-journal-voucher',
    latest_news: '/latest-news',
    account_edit: '/account/edit',
    account_create: '/account/create',
    account_view: '/account/view',
    account_default_view: '/account/default-view',
    account_default_edit: '/account/default-edit',
    account: '/account',
    mfg_company: '/mfg-company',
    opening_data_import: '/opening-data-import',
    pb_create: '/purchase-bill/create',
    pb_view: '/purchase-bill/view',
    pb_edit: '/purchase-bill/edit',
    purchase_bill: '/purchase-bill',
    po_bill_create: '/purchase-bill/po-bill-create',
    bill_return_create: '/purchase-return/bill-return-create',
    payment_create: '/payment/create',
    payment: '/payment',
    general_edit_payment: '/payment/general-edit',
    general_view_payment: '/payment/general-view',
    op_edit_payment: '/payment/opening-edit',
    op_view_payment: '/payment/opening-view',
    pr_create: '/purchase-return/create',
    pr_edit: '/purchase-return/edit',
    pr_view: '/purchase-return/view',
    purchase_return: '/purchase-return',
    po_create: '/purchase-order/create',
    po_edit: '/purchase-order/edit',
    po_view: '/purchase-order/view',
    purchase_order: '/purchase-order',
    copy_po_create: '/purchase-order/copy-bill-create',
    op_purchase_create: '/purchase-bill/op-purchase/create',
    op_purchase_view: '/purchase-bill/op-purchase/view',
    op_purchase_edit: '/purchase-bill/op-purchase/edit',
    accept_invitation: '/accept-invitation',
    decline_invitation: '/decline-invitation',
    invitation: '/invitation',
    transfer_owner: '/transfer-owner',
    unit: '/unit',
    group: '/item-group',
    category: '/item-category',
    transporter: '/transporter',
    bill_info_subscription: '/billing-info/subscription',
    bill_info_user_add_ons: '/billing-info/user-add-ons',
    bi_gst_api_credit: '/billing-info/gst-api-credit',
    bill_info_branding: '/billing-info/branding',
    setting_billing_plan: '/setting/billing-plan',
    payment_info: '/payment-info',
    credit_used_history: '/credit-used-history',
    discount: '/discount',
    payment_success: '/payment-success',
    product_plan: '/product-plan',
    company_product_plan: '/company-product-plan',
    stock_adjustment: '/stock-adjustment',
    stock_adj_add: '/stock-adjustment/add',
    stock_adj_edit: '/stock-adjustment/edit',
    stock_adj_view: '/stock-adjustment/view',
    setting: '/setting',
    invoice_setting: '/setting/invoice-setting',
    user_activity_setting: '/setting/user-activity',
    user_notification_setting: '/setting/user-notifications',
    import_data_log_setting: '/setting/imported-data-log',
    user_account: '/user-account',
    user_permission_setting: '/setting/user-permission',
    edit_up_setting: '/setting/user-permission/edit',
    edit_accept_up_setting: '/setting/user-permission/edit-accept',
    view_up_setting: '/setting/user-permission/view',
    add_up_setting: '/setting/user-permission/add',
    feature_request_setting: '/setting/feature-request',
    details_feature_request_setting: '/setting/feature-request/details',
    roadmap_feature_request_setting: '/setting/feature-request/roadmap',
    survey_feature_request_setting: '/setting/feature-request/survey',
    email_preference: '/setting/email-preference',
    general_setting: '/setting/general-setting',
    printing_templates: '/setting/printing-templates',
    statutory_view_tcs: '/setting/statutory-info/view-tcs',
    statutory_edit_tcs: '/setting/statutory-info/edit-tcs',
    statutory_view_tds: '/setting/statutory-info/view-tds',
    statutory_edit_tds: '/setting/statutory-info/edit-tds',
    statutory_view_gst: '/setting/statutory-info/view-gst',
    statutory_edit_gst: '/setting/statutory-info/edit-gst',
    statutory_info: '/setting/statutory-info',
    admin_login: '/admin-login',
    active_email: '/active-email',
    verify_email_preference: '/verify-email-preference',
    sales_summary_report: '/report/sales/sales-summary-invoice-wise',
    sales_return_summary: '/report/sales/sales-return-summary',
    sales_return_item: '/report/sales/sales-return-item-wise',
    purchase_summary: '/report/purchase/purchase-summary',
    ledger_report: '/report/ledger',
    view_ledger_report: '/report/ledger-view',
    balance_sheet: '/report/balance-sheet',
    profit_loss_report: '/report/profit-loss-report',
    expense_report: '/report/expense-report',
    receivable_invoice_report: '/report/receivable-report-invoice',
    customer_invoice_report: '/report/customer-invoice-report',
    customer_invoice_detail: '/report/customer-invoice-report-detail',
    customer_outstanding_report: '/report/customer-outstanding-report',
    account_aging_report_details: '/report/account-receivable-aging-detail-report',
    payment_received_report: '/report/receivable-report-payment-wise',
    vendor_outstanding_report: '/report/vendor-outstanding-report',
    payable_bill_report: '/report/payable-report-bill',
    receivable_customer_report: '/report/receivable-customer-report',
    payable_vendor_report: '/report/payable-vendor-report',
    stock_inventory_report: '/report/stock-inventory-report',
    stock_valuation_report: '/report/stock-valuation-report',
    sales_item_report: '/report/sales-item-report',
    low_stock_report: '/report/low-stock-report',
    stock_register: '/report/stock-register',
    report_item_party: '/report/report-item-by-party',
    date_customer_item_report: '/report/date-customer-item-report',
    date_vendor_item_report: '/report/date-vendor-item-report',
    report_item_vendor: '/report/report-item-by-vendor',
    item_stock_ledger: '/report/item-stock-ledger',
    adjust_costing: '/report/adjusted-coasting',
    bill_vendor_report: '/report/bill-vendor-report',
    bill_vendor_report_details: '/report/bill-vendor-report-details',
    pending_challan_report: '/report/pending-delivery-challan-report',
    batch_item_stock: '/report/batch-item-stock',
    tds_report: '/report/tds-report',
    tcs_report: '/report/tcs-report',
    total_receivable_report: '/report/total-receivable-report',
    total_payable_report: '/report/total-payable-report',
    long_time_pending_receipt_report: '/report/long-time-pending-receipt-report',
    total_cash_collected_report: '/report/total-cash-collected-report',
    total_bank_collected_report: '/report/total-bank-collected-report',
    unavoidable_payable_report: '/report/unavoidable-payable-report',
    total_cash_balance_report: '/report/total-cash-balance-report',
    total_bank_balance_report: '/report/total-bank-balance-report',
    total_sales_report: '/report/total-sales-report',
    total_purchase_report: '/report/total-purchase-report',
    expected_payable_report: '/report/expected-payable-report',
    expected_receivable_report: '/report/expected-receivable-report',
    purchase_payment_report: '/report/purchase-payment-report',
    purchase_return_summary: '/report/purchase/purchase-return-summary',
    purchase_return_item_wise: '/report/purchase/purchase-return-item-wise',
    item_wise_tax_report: '/report/item-wise-tax-report',
    report: '/report',
    si_create: '/sales-invoice/create',
    si_copy_invoice_create: '/sales-invoice/copy-invoice-create',
    si_quotation_invoice_create: '/sales-invoice/quotation-invoice-create',
    si_challan_invoice_create: '/sales-invoice/challan-invoice-create',
    si_edit: '/sales-invoice/edit',
    si_view: '/sales-invoice/view',
    si_print: '/sales-invoice/print',
    si_eway_bill: '/sales-invoice/e-way-bill',
    si_eway_bill_details: '/sales-invoice/e-way-bill-details',
    si_op_inv_create: '/sales-invoice/opening-invoice/create',
    si_op_inv_edit: '/sales-invoice/opening-invoice/edit',
    si_op_inv_view: '/sales-invoice/opening-invoice/view',
    sales_invoice: '/sales-invoice',
    receipt_create: '/receipt/create',
    receipt_general_edit: '/receipt/general-edit',
    receipt_general_view: '/receipt/general-view',
    receipt: '/receipt',
    sr_create: '/sales-return/create',
    sr_edit: '/sales-return/edit',
    sr_view: '/sales-return/view',
    sr_inv_return_create: '/sales-return/invoice-return-create',
    sales_return: '/sales-return',
    quotation_create: '/quotation/create',
    quotation_edit: '/quotation/edit',
    quotation_view: '/quotation/view',
    quotation: '/quotation',
    dc_create: '/delivery-challan/create',
    dc_edit: '/delivery-challan/edit',
    dc_view: '/delivery-challan/view',
    dc_quotation_challan_create: '/delivery-challan/quotation-challan-create',
    delivery_challan: '/delivery-challan',
    bulk_inv_update: '/bulk-salesinvoice-update',
    series: '/setting/series-configuration',
    series_create: '/setting/series-configuration/create',
    series_edit: '/setting/series-configuration/edit',
    series_view: '/setting/series-configuration/view',
    customer_pending_invoice_report: '/report/customer-pending-invoice-report',
    sr_eway_bill: '/sales-return/e-way-bill',
    sr_eway_bill_details: '/sales-return/e-way-bill-details',
    daybook_report: '/report/daybook-report',
    trial_balance: '/report/trial-balance-sheet',
    sales_register_detail: '/report/sales-register',
    purchase_register_detail: '/report/purchase-register',
    sign_in: '/sign-in',
    sign_up: '/sign-up',
    forgot_password: '/forgot-password',
    reset_password: '/reset-password',
    item_rate_card: '/report/item-rate-card',
    redirect_to_account: `${process.env.LOGIN_DOMAIN}/log-in?product=app`,
    log_in: '/log-in',
    barcode: '/item/barcode',
    barcode_settings: '/setting/barcode-settings',
    bank_reconciliation_list: '/bank-reconciliation-list',
    brs_import_history_view: '/bank-reconciliation-list/import-history-view',
    brs_template: '/bank-reconciliation-list/brs-template',
    bank_reconciliation: '/bank-reconciliation-list/bank-reconciliation',
    brs_mapping: '/bank-reconciliation-list/brs-mapping',
    whatsapp_setup: '/setting/whatsapp-setup',
    barcode_printing: '/barcode-print',
    contra: '/contra',
    contra_create: '/contra/create',
    contra_edit: '/contra/edit',
    contra_view: '/contra/view',
    dc_eway_bill: '/delivery-challan/e-way-bill',
    whatsapp_sharing: '/setting/whatsapp-sharing',
    dc_eway_bill_details: '/delivery-challan/e-way-bill-details',
    pending_purchaseorder: '/report/pending-purchase-order'
}
export default CommonRouter
